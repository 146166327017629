<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Datatable from "@/router/layouts/table-datatable.vue";
import Pagination from "@/router/layouts/table-pagination.vue";
import axios from "axios";
import Swal from "sweetalert2";
import _ from "lodash";

export default {
  page: {
    title: "Pelaksanaan",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Datatable, Pagination },
  data() {
    let sortOrders = []; // select sortOrder

    // definisi kolom yang dipakai
    let columns = [
      {
        width: "50px",
        label: "No",
        name: "-",
      },
      {
        width: "auto",
        label: "Pelaksanaan",
        name: "-",
      },
      {
        width: "150px",
        label: "Aksi",
        name: "-",
      }, // jika tidak ada sorting kasih name dengan karakter -
    ];

    // cek sorting
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });

    return {
      title: "Pelaksanaan",
      isWrite: localStorage.getItem("isWrite"),
      loadingTable: true,
      tableData: [],
      columns: columns,
      sortKey: "pelaksanaan",
      sortOrders: sortOrders,
      tableFilter: {
        page: 1,
        per_page: 10,
        search: "",
        column: "idjadwal_pelaksanaan",
        dir: "desc",
        status: "all",
        pelaksanaan: "",
      },
      pagination: {
        lastPage: "",
        currentPage: "",
        total: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
        from: "",
        to: "",
        links: "",
      },
      formPayload: {},
      modalTambah: false,
      modalEdit: false,
    };
  },
  mounted() {
    this.filterData = _.debounce(this.getTableData.bind(this), 500);
    this.getTableData();
  },
  methods: {
    async getTableData() {
      this.loadingTable = true;
      const data = await axios({
        method: "get",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pelaksanaan",
        params: this.tableFilter,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err get data:", err);
          return false;
        });
      if (data) {
        this.tableData = data.data.data;
        this.pagination.total = data.data.total;
        this.pagination.from = data.data.from;
        this.pagination.to = data.data.to;
        this.pagination.links = data.data.links;
        this.pagination.lastPageUrl = data.last_page_url;
        this.pagination.nextPageUrl = data.next_page_url;
        this.pagination.prevPageUrl = data.prev_page_url;
        this.pagination.per_page = this.tableFilter.per_page;
        this.pagination.page = this.tableFilter.page;
      }
      this.loadingTable = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      // this.tableFilter.per_page = link.get('per_page');
      this.getTableData();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getTableData();
    },
    create() {
      this.formPayload = {
        pelaksanaan: "",
      };
      this.modalTambah = true;
    },
    async store() {
      let payload = { ...this.formPayload };
      const data = await axios({
        method: "post",
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/pelaksanaan",
        data: payload,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err save data:", err);
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: err.response.data.data,
          });
          return false;
        });
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Data berhasil disimpan",
        });
        this.getTableData();
        this.modalTambah = false;
      }
    },
    edit(row) {
      this.formPayload = {};
      this.formPayload = { ...row };
      this.modalEdit = true;
    },
    async update() {
      let payload = { ...this.formPayload };
      const data = await axios({
        method: "put",
        url:
          process.env.VUE_APP_BACKEND_URL_VERSION +
          "master/pelaksanaan/" +
          payload?.idjadwal_pelaksanaan,
        data: payload,
        headers: {
          Authorization: `Bearer ${localStorage.access_token}`,
        },
      })
        .then((response) => response.data)
        .catch((err) => {
          console.log("err save data:", err);
          Swal.fire({
            icon: "error",
            title: "Terjadi Kesalahan",
            text: err.response.data.data,
          });
          return false;
        });
      if (data) {
        Swal.fire({
          icon: "success",
          title: "Data berhasil diperbarui",
        });
        this.getTableData();
        this.modalEdit = false;
      }
    },
    destroy(id) {
      Swal.fire({
        icon: "warning",
        title: "Apakah Anda yakin menghapus data ini ?",
        text: "Data yang dihapus tidak dapat dipulihkan kembali.",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: `Ya`,
        denyButtonText: `Tidak`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await axios({
            method: "delete",
            url:
              process.env.VUE_APP_BACKEND_URL_VERSION +
              "master/pelaksanaan/" +
              id,
            headers: {
              Authorization: `Bearer ${localStorage.access_token}`,
            },
          })
            .then((response) => response.data)
            .catch((err) => {
              console.log("err delete data:", err);
              Swal.fire({
                icon: "error",
                text: "Terjadi Kesalahan",
                title: "Silahkan coba kembali beberapa saat lagi",
              });
              return false;
            });
          if (data) {
            Swal.fire({
              icon: "success",
              title: "Data Berhasil dihapus",
            });
            this.getTableData();
          }
        }
      });
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="card-title">Pelaksanaan</div>
              </div>
              <div class="col-md-6">
                <div class="text-end">
                  <button
                    class="btn btn-success btn-sm"
                    @click="create()"
                    v-if="isWrite == 1"
                  >
                    <i class="fa fa-plus me-1"></i> Tambah
                  </button>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <hr />
              <div class="form-group col-md-3">
                <label for="filterPelaksanaan">Pelaksanaan</label>
                <input
                  type="text"
                  id="filterPelaksanaan"
                  class="form-control"
                  placeholder="Filter Pelaksanaan"
                  v-model="tableFilter.pelaksanaan"
                  @input="filterData"
                />
              </div>
            </div>
            <div class="row mt-2">
              <hr />
              <div class="col-12">
                <table class="mb-2">
                  <tr>
                    <td>Show</td>
                    <td>
                      <select
                        class="form-control form-control-sm"
                        id="showpaginatetable"
                        v-model="tableFilter.per_page"
                        @change="getTableData()"
                      >
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                        <option value="500">500</option>
                      </select>
                    </td>
                    <td>Entries</td>
                  </tr>
                </table>
              </div>
              <Datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="99" class="text-center">
                      <i class="fas fa-spinner fa-spin"></i>
                      Loading
                    </td>
                  </tr>
                  <tr v-else-if="tableData.length < 1">
                    <td colspan="99" class="text-center">Data Empty</td>
                  </tr>
                  <tr v-else v-for="(row, index) in tableData" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ row.pelaksanaan }}</td>
                    <td>
                      <div class="btn-group">
                        <button
                          class="btn btn-warning btn-sm"
                          @click="edit(row)"
                          v-if="isWrite == 1"
                        >
                          <i class="fa fa-edit me-1"></i>
                          Edit
                        </button>
                        <button
                          class="btn btn-danger btn-sm"
                          @click="destroy(row.idjadwal_pelaksanaan)"
                          v-if="isWrite == 1"
                        >
                          <i class="fa fa-trash me-1"></i> Hapus
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Datatable>
              <Pagination
                :pagination="pagination"
                @to-page="toPage"
              ></Pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
  <b-modal v-model="modalTambah" hide-footer title="Tambah Pelaksanaan">
    <form @submit.prevent="store" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="formPelaksanaan">Pelaksanaan</label>
        <input
          type="text"
          id="formPelaksanaan"
          class="form-control"
          placeholder="Pelaksanaan..."
          v-model="formPayload.pelaksanaan"
        />
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Simpan
        </button>
      </div>
    </form>
  </b-modal>

  <b-modal v-model="modalEdit" hide-footer title="Edit Pelaksanaan">
    <form @submit.prevent="update" class="needs-validation" novalidate>
      <div class="mb-3">
        <label for="formPelaksanaan">Pelaksanaan</label>
        <input
          type="text"
          id="formPelaksanaan"
          class="form-control"
          placeholder="Pelaksanaan..."
          v-model="formPayload.pelaksanaan"
        />
      </div>
      <div class="mb-3 text-end">
        <button type="submit" class="btn btn-primary btn-sm">
          <i class="fa fa-save"></i> Simpan
        </button>
      </div>
    </form>
  </b-modal>
</template>
